import React from 'react';
import { useAppSelector } from '../../hooks/useReduxHooks';
import UserNav from './UserNav';
import AdminNav from './AdminNav';
import TopBar from './Topbar';

const Header = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <>
      <TopBar />
      {user?.user?.user_type === 'Admin' ? <AdminNav /> : <UserNav />}
    </>
  );
};
export default Header;
