import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AMEX from '../../assets/images/card/AMEX.png'
import JCB from '../../assets/images/card/JCB.png'
import Visa from '../../assets/images/card/Visa.png'
import Dinner from '../../assets/images/card/dinner.png'
import master from '../../assets/images/card/master.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css'; // Import Swiper core styles
import 'swiper/css/navigation'; // Import Navigation styles if using Navigation
import 'swiper/css/pagination'; // Import Pagination styles if using Pagination
import { Navigation, Pagination, Autoplay } from 'swiper/modules';


const FooterContent = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="footer_box flex lg:justify-around lg:flex-row items-center flex-col sm:gap-10 gap-5 px-20 py-10 md:px-10">
                <div className='flex md:flex-row sm:gap-5 lg:gap-8 gap-5 flex-wrap items-center justify-around'>
                    <a className="fotter_link m-0 text-center cursor-pointer" onClick={() => { navigate("/About-Us") }}>{t("ABOUT_US")}</a>
                    <a className="fotter_link m-0 text-center cursor-pointer" onClick={() => { navigate("/Inquiry") }}>{t("INQUIRY")}</a>
                    <a className="fotter_link m-0 text-center cursor-pointer" onClick={() => { navigate("/Faq") }}>{t("HELP_AND_FAQ")}</a>
                    <a className="fotter_link m-0 text-center cursor-pointer" onClick={() => { navigate("/Privacy-Policy") }}>{t("PRIVACY_POLICY")}</a>
                    <a className="fotter_link m-0 text-center cursor-pointer" onClick={() => { navigate("/terms-of-service") }}>{t("TERMS_OF_SERVICE")}</a>
                </div>
                <div className="grid md:grid-cols-5 gap-4 grid-rows md:flex hidden">
                    <img className="w-24 h-12 overflow-hidden object-cover" src={AMEX} />
                    <img className="w-24 h-12 overflow-hidden object-cover" src={JCB} />
                    <img className="w-24 h-12 overflow-hidden object-cover" src={Visa} />
                    <img className="w-24 h-12 overflow-hidden object-cover" src={Dinner} />
                    <img className="w-24 h-14 overflow-hidden object-cover" src={master} />
                </div>
                {/* <div className="grid md:grid-cols-5 gap-4 grid-rows md:hidden flex"> */}
               
            </div>
            <section id="Slider" className='md:hidden flex my-2 justify-center items-center ml-10'>
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={5}
                    slidesPerView={4}
                    loop={true}
                    centeredSlides={true}
                    speed={4000}
                    autoplay={{ delay: 0, disableOnInteraction: false }} 
                    breakpoints={{
                        300: {
                        slidesPerView: 4,
                        spaceBetween: 2,
                        },
                        400: {
                        slidesPerView: 4,
                        spaceBetween: 2,
                        },
                        640: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                        },
                        800: {
                        slidesPerView: 4,
                        spaceBetween: 4,
                        },
                    }}
                    >
                        <SwiperSlide key='1'>
                            <img className="w-24 h-12 overflow-hidden object-cover" src={AMEX} />
                        </SwiperSlide>
                        <SwiperSlide key='2'>
                            <img className="w-24 h-12 overflow-hidden object-cover" src={JCB} />
                        </SwiperSlide>
                        <SwiperSlide key='3'>
                            <img className="w-24 h-12 overflow-hidden object-cover" src={Visa} />
                        </SwiperSlide>
                        <SwiperSlide key='4'>
                            <img className="w-24 h-12 overflow-hidden object-cover" src={Dinner} />
                        </SwiperSlide>
                        <SwiperSlide key='5'>
                            <img className="w-24 h-12 overflow-hidden object-cover" src={master} />
                        </SwiperSlide>
                    </Swiper>
                </section>
            <div className="virtual-line text-center">
                <p>@2024 VIRTUAL FASHION MALL produced<br className='block md:hidden' /> by studioARATA</p>
            </div>
        </>
    );
};

export default FooterContent;

